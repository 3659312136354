import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { RouteName } from "../../routes";
import { LocaleService } from "../locale.service";
import { SeoItem } from "./seo-item.model";

@Injectable({
  providedIn: "root",
})
export class SeoRichDataService {
  private defaultText: string = "";
  private currentRoute: RouteName = null;
  private currentObject: any = null;
  private seoItem: SeoItem = null;
  private link: HTMLLinkElement;
  private ouTitle: string;

  constructor(
    private localeService: LocaleService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    const logoData: any = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: environment.websiteUrl + "/assets/img/logos/logo-desktop.png",
    };

    const structuredDataScript = this.document.createElement("script");
    structuredDataScript.setAttribute("type", "application/ld+json");
    structuredDataScript.setAttribute("id", "logo");
    structuredDataScript.innerHTML = JSON.stringify(logoData);
    this.document.head.appendChild(structuredDataScript);
  }

  setCurrentRoute(currentRoute: RouteName): void {
    this.currentRoute = currentRoute;
  }

  setDefaultText(defaultText: string): void {
    this.defaultText = defaultText;
  }

  setCurrentObject(currentObject): void {
    this.currentObject = currentObject;
  }

  setSeoItem(seoItem: SeoItem): void {
    this.seoItem = seoItem;
  }

  reset(): void {
    this.setCurrentObject(null);
    this.setSeoItem(null);
    this.setCurrentRoute(null);
    this.removeRichData();
  }

  removeRichData(): void {
    const richData = this.document.querySelectorAll(
      '[type="application/ld+json"]',
    );
    richData.forEach((richElement) => {
      if (richElement.getAttribute("id") !== "logo") {
        richElement.remove();
      }
    });
  }

  refresh(): void {
    this.setBreadcrumbs();
    this.setPageStructuredData();
    this.setArticleAndLoanStructuredData();
  }

  setPageStructuredData(): void {
    if (this.currentObject && this.currentRoute === RouteName.CUSTOM_PAGE) {
      if (this.currentObject.organizational_unit) {
        this.ouTitle = this.currentObject.organizational_unit.title;
      } else {
        this.ouTitle = "Razvojna Banka Crne Gore - RBCG";
      }

      const structuredPageData: any = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: this.currentObject.seo_title,
        description: this.currentObject.seo_description,
        image: [environment.mediaUrl + "/" + this.currentObject.seo_image_url],
        publisher: {
          "@type": "Organization",
          name: this.ouTitle,
          logo: {
            "@type": "ImageObject",
            url: environment.websiteUrl + "/assets/img/logos/logo-desktop.png",
            width: 356,
            height: 64,
          },
        },
      };

      const structuredPageDataScript = this.document.createElement("script");
      structuredPageDataScript.setAttribute("type", "application/ld+json");
      structuredPageDataScript.innerHTML = JSON.stringify(structuredPageData);
      this.document.head.appendChild(structuredPageDataScript);
    }
  }

  setArticleAndLoanStructuredData(): void {
    if (
      this.currentObject &&
      (this.currentRoute === RouteName.ARTICLE_DETAIL ||
        this.currentRoute === RouteName.LOAN_DETAIL)
    ) {
      const structurableArticleTypes = ["news", "default"];
      if (
        structurableArticleTypes.indexOf(
          this.currentObject.article_type.label,
        ) === -1
      ) {
        return;
      }

      const structuredData: any = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id":
            this.localeService.getLocaleBaseUrl() + this.currentObject.getUrl(),
        },
        headline: this.currentObject.seo_title,
        description: this.currentObject.seo_description,
        image: [environment.mediaUrl + "/" + this.currentObject.seo_image_url],
        datePublished: this.currentObject.publishedAtSchemaDate,
        dateModified: this.currentObject.updatedAtSchemaDate,
        publisher: {
          "@type": "Organization",
          name: "Razvojna Banka Crne Gore - RBCG",
          logo: {
            "@type": "ImageObject",
            url: environment.websiteUrl + "/assets/img/logos/logo-desktop.png",
            width: 356,
            height: 64,
          },
        },
      };

      if (this.currentObject.seo_keywords) {
        structuredData.keywords = this.currentObject.seo_keywords.split(",");
      }

      const structuredDataScript = this.document.createElement("script");
      structuredDataScript.setAttribute("type", "application/ld+json");
      structuredDataScript.innerHTML = JSON.stringify(structuredData);
      this.document.head.appendChild(structuredDataScript);
    }
  }

  setBreadcrumbs(): void {
    if (this.currentObject) {
      const breadcrumbData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [],
      };
      let position = 1;

      breadcrumbData.itemListElement.push({
        "@type": "ListItem",
        position: position++,
        name: $localize`:@@breadcrumbs_home:Početna`,
        item: this.localeService.getLocaleBaseUrl(),
      });

      if (this.currentRoute === RouteName.ARTICLE_DETAIL) {
        breadcrumbData.itemListElement.push({
          "@type": "ListItem",
          position: position++,
          name: this.currentObject.title,
        });
      }

      if (this.currentRoute === RouteName.CUSTOM_PAGE) {
        if (this.currentObject.breadcrumbs) {
          this.currentObject.breadcrumbs.forEach((breadcrumb) => {
            breadcrumbData.itemListElement.push({
              "@type": "ListItem",
              position: position++,
              name: breadcrumb.title,
              item: this.localeService.getLocaleBaseUrl() + breadcrumb.path,
            });
          });
        }
      }

      breadcrumbData.itemListElement.push({
        "@type": "ListItem",
        position: position++,
        name: this.currentObject.title,
      });

      const breadcrumbScript = this.document.createElement("script");
      breadcrumbScript.setAttribute("type", "application/ld+json");
      breadcrumbScript.innerHTML = JSON.stringify(breadcrumbData);
      this.document.head.appendChild(breadcrumbScript);
    }
  }
}
