import {
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { filter, take, tap } from "rxjs/operators";
import { DetailService } from "../../../@modules/detail/services/detail.service";
import { MenuItem } from "../../../@modules/menu/models/menu-item.model";
import { LocaleService } from "../../../Library/services/locale.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  currentYear: number;
  locale: string;
  apiParams: any;
  footerMenu: MenuItem[];
  legalMenu: MenuItem[];

  constructor(
    private localeService: LocaleService,
    private detailService: DetailService,
    @Inject(LOCALE_ID) public localeCode: string,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this.detailService.details$
      .pipe(
        filter((result) => result != null),
        tap((result) => {
          this.footerMenu = result.additional.menus.footer.items;
        }),
        take(1)
      )
      .subscribe();
  }
}
