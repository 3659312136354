<section class="relative md:w-full md:container" style="color: var(--page-accent);">
  <div class="relative max-w-max mx-auto overflow-hidden lg:rounded-48">
    <picture *ngIf="contentModule.image">
      <source [srcset]="contentModule.image.i_1920x1440" media="(min-width: 768px)">
      <source [srcset]="contentModule.image.i_904x678" media="(max-width: 768px)">
      <img class="absolute inset-0 object-center object-cover w-full h-full"
           [srcset]="contentModule.image.i_904x678"
           [alt]="contentModule.image.alt">
    </picture>
    <div class="gradient relative z-10 flex flex-col gap-6 lg:gap-40 items-center pt-6 pb-4 px-4 lg:p-14">
      <h1 *ngIf="!contentModule.name_hidden && contentModule.name"
        class="text-neutral-white font-500 font-montserrat text-24 leading-150 lg:leading-110 lg:text-42 text-left lg:text-center">
        {{contentModule.name}}
      </h1>
      <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
        <app-slot-a082 *ngFor="let slotable of contentModule.slotables" [slotable]="slotable"></app-slot-a082>
      </div>
    </div>
  </div>
</section>
