<div class="w-full h-full relative !flex flex-row md:flex-col group hover:cursor-pointer border-gradient-gold p-4 border border-neutral-100 rounded-16 lg:rounded-24 bg-neutral-white">
  <img *ngIf="slotable.image"
       class="relative w-[94px] self-start md:w-full aspect-3/2 lg:aspect-4/3 object-cover object-center rounded-8 lg:rounded-16"
       width="272"
       height="204"
       srcset="{{ slotable.image?.i_106x81 }} 106w, {{ slotable.image?.thumbnail }} 272w"
       sizes="(max-width: 768px) 106px, (min-width: 768px) 272px"
       alt="{{ slotable.title }}"
       title="{{ slotable.title }}"
       [src]="slotable.image?.thumbnail"
       loading="lazy">
  <a [appFlLink]="slotable.url" title="{{ slotable.title }}" class="absolute inset-0"></a>

  <div class="md:pt-4 pl-4 pr-2 lg:px-2 lg:pb-4 md:pb-0 flex flex-1 flex-col gap-[6px]">
    <h3
      class="text-neutral-900 group-hover:text-deep-blue transition-all ease-in-out text-16 lg:text-20 font-400 line-clamp-4">
      {{ slotable.title }}
    </h3>
    <span class="text-neutral-400 font-500 text-14 lg:text-16 lg:mb-2"
          *ngIf="slotable.article_type_label !== 'biography'">
      {{ slotable.publishedAtDate | date: 'dd.MM.yyyy.' }}
    </span>
    <h4 class="text-14 md:text-16 mt-2 text-neutral-800 group-hover:text-deep-blue"
        *ngIf="slotable.article_type_label === 'biography' && slotable.intro_content"
        [ngClass]="{'font-700': !slotable.article_type_label === 'biography'}"
    >
      {{ slotable.intro_content }}
    </h4>
  </div>
</div>
