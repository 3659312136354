import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import {
  Inject,
  Injectable,
  LOCALE_ID,
  Optional,
  PLATFORM_ID,
} from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { REQUEST } from "@nguniversal/express-engine/tokens";
import { environment } from "../../../../environments/environment";
import { RouteName } from "../../routes";
import { SeoItem } from "./seo-item.model";

@Injectable({
  providedIn: "root",
})
export class SeoGeneralService {
  private defaultText: string = "";
  private currentRoute: RouteName = null;
  private currentObject: any = null;
  private seoItem: SeoItem = null;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(REQUEST) private request: any
  ) {}

  setCurrentObject(currentObject): void {
    this.currentObject = currentObject;
  }

  setSeoItem(seoItem: SeoItem): void {
    this.seoItem = seoItem;
  }

  reset(): void {
    this.titleService.setTitle("");
    this.metaTagService.updateTag({ name: "description", content: "" });
    this.metaTagService.removeTag('name="keywords"');
    this.removeAlternateUrls();
  }

  refresh(seoItem: SeoItem): void {
    this.seoItem = seoItem;
    this.titleService.setTitle(seoItem.getTitle());
    this.metaTagService.updateTag({
      name: "description",
      content: seoItem.getDescription(),
    });
    if (seoItem.keywords) {
      this.metaTagService.updateTag({
        name: "keywords",
        content: seoItem.keywords,
      });
    }
    this.refreshCanonicalUrl();
  }

  refreshCanonicalUrl(): void {
    const locale = this.locale === "en" ? "/en" : "";
    // Get the URL
    let canonicalUrl = this.seoItem?.canonical_url
      ? this.seoItem?.canonical_url
      : isPlatformBrowser(this.platformId)
      ? this.document.URL
      : environment.websiteUrl + locale + this.request.url;

    // Remove the current HTML tag
    const currentTagElements = this.document.querySelectorAll(
      'link[rel="canonical"]'
    );
    currentTagElements.forEach((element) => {
      element.remove();
    });

    // Create a new HTML tag in the <head>
    const link = this.document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute("href", canonicalUrl);
    this.document.head.appendChild(link);
  }

  setCurrentObjectAlternateUrls(): void {
    // Default hreflang (current locale)
    this.setAlternateUrl(this.currentObject, this.locale);
    this.setAlternateUrl(this.currentObject, "x-default");

    switch (this.locale) {
      case "sr-Latn-ME":
        if (
          this.currentObject.translations &&
          this.currentObject.translations.length > 0
        ) {
          const meObject = this.currentObject.getTranslation("me");
          if (meObject) {
            this.setAlternateUrl(meObject, "en");
          }
        }
        break;
      case "en":
        if (
          this.currentObject.translations &&
          this.currentObject.translations.length > 0
        ) {
          const meObject = this.currentObject.getTranslation("me");
          if (meObject) {
            this.setAlternateUrl(meObject, "sr-Latn-ME");
          }
        }
        break;
    }
  }

  setAlternateUrl(item: any, locale: string): void {
    const newhtmlLinkElement = this.document.createElement("link");
    newhtmlLinkElement.setAttribute("rel", "alternate");
    newhtmlLinkElement.setAttribute("hreflang", locale);
    let url = environment.websiteUrl;
    url += item.url;
    newhtmlLinkElement.setAttribute("href", url);
    this.document.head.appendChild(newhtmlLinkElement);
  }

  removeAlternateUrls(): void {
    const srLatnMe = this.document.querySelectorAll(
      '[rel="alternate"][hreflang="sr-Latn-ME"]'
    );
    const en = this.document.querySelectorAll(
      '[rel="alternate"][hreflang="en"]'
    );
    const defaultHreflang = this.document.querySelectorAll(
      '[rel="alternate"][hreflang="x-default"]'
    );
    srLatnMe.forEach((element) => {
      element.remove();
    });
    en.forEach((element) => {
      element.remove();
    });
    defaultHreflang.forEach((element) => {
      element.remove();
    });
  }
}
