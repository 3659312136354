import { LocationStrategy } from "@angular/common";
import { Directive, Input } from "@angular/core";
import { ActivatedRoute, Router, RouterLinkWithHref } from "@angular/router";
import { LocaleService } from "../services/locale.service";

@Directive({ selector: "a[appFlRouterLink],area[appFlRouterLink]" })
export class FlRouterLinkDirective extends RouterLinkWithHref {
  constructor(
    router: Router,
    route: ActivatedRoute,
    locationStrategy: LocationStrategy,
    private localeService: LocaleService,
  ) {
    super(router, route, locationStrategy);
  }

  @Input()
  set appFlRouterLink(commands: any[]) {
    const finalRoute = ["/"];

    if (commands) {
      commands.forEach((element) => {
        if (typeof element !== "string") {
          finalRoute.push(element.getSlug());
        } else {
          const elements = element.split("/");
          if(elements) {
            elements.forEach((elementChunk) => {
              if (elementChunk && !elementChunk.match(/^[a-zA-Z0-9_\-]+$/i)) {
                throw new Error("Input in FlRouterLink must be array of strings");
              }

              finalRoute.push(this.localeService.getRouteByElement(elementChunk));
            });
          }
        }
      });
    }
    this.routerLink = finalRoute;
  }
}
