import { Component, Input, OnInit } from "@angular/core";
import { Slot } from "../../models/slot.model";

@Component({
  selector: "app-slot-a081",
  templateUrl: "./slot-a081.component.html",
  styles: [],
})
export class SlotA081Component implements OnInit {
  @Input() slotable: Slot;
  cleanDescription: string;

  ngOnInit(): void {
    if (this.slotable.description) {
      this.cleanDescription = this.handleDescription(this.slotable.description);
    }
  }

  handleDescription(val): string {
    return val.replace(/(&nbsp;|<([^>]+)>)/g, "");
  }
}
