import { SeoItem } from "../../../Library/services/seo/seo-item.model";

export const seoRulesDefault = {
  me: new SeoItem({
    title: "Razvojna Banka Crne Gore - Website",
    description: "Razvojna Banka Crne Gore - Website description",
    seo_title: "Razvojna Banka Crne Gore - Website",
    seo_fb_title: "Razvojna Banka Crne Gore - Website",
    seo_fb_description: "Razvojna Banka Crne Gore - Website description fb",
    seo_fb_image_url: "/assets/img/seo/rbcg_seo_me_facebook.jpg",
    seo_fb_type: "website",
    seo_tw_title: "Razvojna Banka Crne Gore - Website",
    seo_tw_description: "Razvojna Banka Crne Gore - Website description tw",
    seo_tw_image_url: "/assets/img/seo/rbcg_seo_me_twitter.jpg",
    seo_keywords: "RBCG, Centar, Crna Gora",
    url: "",
  }),
  en: new SeoItem({
    title: "Development Bank of Montenegro - Website",
    description: "Development Bank of Montenegro - Website description",
    seo_title: "Development Bank of Montenegro - Website",
    seo_fb_title: "Development Bank of Montenegro - Website",
    seo_fb_description: "Development Bank of Montenegro - Website description",
    seo_fb_image_url: "/assets/img/seo/rbcg_seo_en_facebook.jpg",
    seo_description: "Development Bank of Montenegro - Website description",
    seo_tw_title: "Development Bank of Montenegro - Website",
    seo_tw_description: "Development Bank of Montenegro - Website description",
    seo_tw_image_url: "/assets/img/seo/rbcg_seo_en_twitter.jpg",
    seo_fb_type: "website",
    seo_keywords: "RBCG, Center, Montenegro",
    url: "",
  }),
};
