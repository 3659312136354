import { Component, Input } from "@angular/core";
import { Slot } from "../../models/slot.model";

@Component({
  selector: "app-slot-a20",
  templateUrl: "./slot-a20.component.html",
})
export class SlotA20Component {
  @Input() slotable: Slot;
  @Input() slotType: string;
}
