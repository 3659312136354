<div
  *ngIf="item"
  [style]="style"
  class="max-w-max mx-auto mt-header-mobile md:mt-header-desktop overflow-hidden"
>
  <div
    *ngFor="let group of groupedModules; let first = first"
    [ngClass]="{ 'module-type-b': group.hasFixedWidth === 0, 'lg:pt-4': first }"
    class="md:mb-8"
  >
    <div
      *ngFor="let module of group.modules"
      class="flex module-wrapper"
      [ngClass]="{
        'bg-neutral-white': module.is_highlighted,
        'hidden lg:flex': module.mobile_hidden,
        'lg:hidden': module.desktop_hidden
      }"
    >
      <app-content-module
        class="w-full"
        [contentModule]="module"
      ></app-content-module>
    </div>
  </div>
</div>
