import { environment } from "../../environments/environment";
import { currentLocale } from "./routes";
import { SeoItem } from "./services/seo/seo-item.model";

export default class Helpers {
  static excerpt(input: string, length: number = 55): string {
    if (!input) {
      return "";
    }

    if (input.length > length) {
      input = input.substring(0, length);
      if (input.indexOf(" ") === -1) {
        return input.substring(0, length - 3) + "…";
      }
      let lastIndex = input.lastIndexOf(" ");
      input = input.substring(0, lastIndex);

      let newLastIndex = input.lastIndexOf(" ");
      let lastWord = input.substring(newLastIndex, lastIndex);

      // Removes every word which length is smaller then 3 characters
      while (lastWord.length < 4 && input.indexOf(" ") !== -1) {
        lastIndex = newLastIndex;
        input = input.substring(0, lastIndex);
        newLastIndex = input.lastIndexOf(" ");
        lastWord = input.substring(newLastIndex, lastIndex);
      }
      // Removes dots and comma
      if (
        input.charAt(input.length - 1) === "." ||
        input.charAt(input.length - 1) === ","
      ) {
        input = input.slice(0, -1);
      }

      input = input.substring(0, lastIndex) + "…";
    }
    return input;
  }

  static attachSeoImages(item: any, seoItem: SeoItem): SeoItem {
    if (item.seo_image) {
      seoItem.image = item.seo_image.facebook;
    } else {
      seoItem.image =
        environment.websiteUrl +
        `/assets/img/seo/rbcg_seo_${currentLocale}_facebook.jpg`;
    }

    if (item.seo_fb_image) {
      seoItem.seo_fb_image_url = item.seo_fb_image.facebook;
    } else {
      seoItem.seo_fb_image_url =
        environment.websiteUrl +
        `/assets/img/seo/rbcg_seo_${currentLocale}_facebook.jpg`;
    }

    if (item.seo_tw_image) {
      seoItem.seo_tw_image_url = item.seo_tw_image.twitter;
    } else {
      seoItem.seo_tw_image_url =
        environment.websiteUrl +
        `/assets/img/seo/rbcg_seo_${currentLocale}_twitter.jpg`;
    }

    return seoItem;
  }
}
