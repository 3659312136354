import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
} from "@angular/core";
import Swiper, { Grid } from "swiper";
import { ContentModule } from "../../models/content-module.model";
import { ContentModuleBaseComponent } from "../content-module-base.component";

@Component({
  selector: "app-content-module-a111",
  templateUrl: "./content-module-a111.component.html",
  styleUrls: ["./content-module-a111.component.scss"],
})
export class ContentModuleA111Component
  implements ContentModuleBaseComponent, AfterViewInit
{
  @Input() contentModule: ContentModule;
  constructor(@Inject(PLATFORM_ID) public platformId) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const swiper = new Swiper(".swiper-module-a110", {
        modules: [Grid],
        slidesPerView: 1.5,
        spaceBetween: 16,
        loop: false,
        watchOverflow: true,
        grid: {
          rows: 3,
          fill: "row",
        },
      });
    }
  }
}
