<section class="relative md:w-full px-4 md:mx-0 pt-6 pb-8 lg:py-12 lg:rounded-48 md:px-12"
         [ngClass]="{ 'bg-deep-blue highlighted-module': contentModule.is_highlighted}"
         style="color: var(--page-accent);">
  <header *ngIf="!contentModule.name_hidden"
          class="container w-full mb-6 lg:mb-12 flex justify-between items-center">
    <h1 class="text-24 md:text-42 ml-2 md:ml-0 font-500 font-montserrat" [ngClass]="contentModule.is_highlighted ? 'text-neutral-white' : 'text-neutral-900'">
      {{ contentModule.name }}
    </h1>
    <a *ngIf="!isMobileLayout && contentModule.slot_type === 'article' && contentModule.data_source === 'automatic'"
       [routerLink]="searchRoute"
       [queryParams]="queryParams"
       class="btn btn__primary">
      <span i18n="@@IRF_Module_Show_All">Vidi sve</span>
      <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
    </a>
  </header>

  <div class="container grid gap-4 grid-cols-6 md:grid-cols-24">
    <div *ngFor="let slotable of contentModule.slotables"
         class="col-span-8 mb-2 md:mb-0">
      <app-slot-a50 [slotable]="slotable"></app-slot-a50>
    </div>
  </div>
  <a *ngIf="isMobileLayout && contentModule.slot_type === 'article' && contentModule.data_source === 'automatic'"
     [routerLink]="searchRoute"
     [queryParams]="queryParams"
     class="btn btn__primary container mt-12">
    <span i18n="@@IRF_Module_Show_All">Pogledaj sve</span>
    <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
  </a>
</section>
