<div class="w-full relative h-full flex flex-col justify-start p-4">
  <fa-icon class="text-48 bg-red gradient-icon mb-8" [icon]="['fal', slotable.icon ]"></fa-icon>
  <div class="flex flex-col flex-1 space-y-4 slot-a80-border pb-7 md:pb-0 pr-4">
    <h3 
      class=" text-22 font-montserrat lg:text-24 font-500 line-clamp-2"
      [ngClass]="isHighlighted ? 'text-neutral-white' : 'text-neutral-900'"
    >
      {{ slotable.title }}
    </h3>
    <p 
      class=" text-16 font-400 line-clamp-3"
      [ngClass]="isHighlighted ? 'text-neutral-400' : 'text-neutral-800'">
      {{ cleanDescription }}
    </p>
  </div>
</div>
