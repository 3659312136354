<div class="w-full bg-transparent md:bg-neutral-white relative h-full flex flex-row md:flex-col items-center gap-6 md:gap-8 md:justify-between p-0 md:p-8 rounded-24">
  <div class="flex justify-center self-end p-4 md:p-6 bg-neutral-white/[0.1] md:bg-gradient-to-r from-gradient-start
  to-gradient-end rounded-12 md:rounded-24 items-center">
    <fa-icon class="text-32 md:text-40 text-gold-800" [icon]="['fal', slotable.icon]"></fa-icon>
  </div>
  <div class="flex flex-col w-full gap-1 md:gap-3">
    <h3 class=" text-neutral-white md:text-neutral-900 font-500 font-montserrat text-22 lg:text-30 leading-110 ">
      {{slotable.title}}
    </h3>
    <p class="text-neutral-400 md:text-neutral-800 text-14 lg:text-16 font-400 line-clamp-3">
      {{ cleanDescription }}
    </p>
  </div>
</div>
