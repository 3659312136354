import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ContentModuleDirective } from "../directives/content-module.directive";
import { ContentModule } from "../models/content-module.model";
import { ContentModuleA010Component } from "./content-module-a010/content-module-a010.component";
import { ContentModuleA011Component } from "./content-module-a011/content-module-a011.component";
import { ContentModuleA020Component } from "./content-module-a020/content-module-a020.component";
import { ContentModuleA030Component } from "./content-module-a030/content-module-a030.component";
import { ContentModuleA031Component } from "./content-module-a031/content-module-a031.component";
import { ContentModuleA032Component } from "./content-module-a032/content-module-a032.component";
import { ContentModuleA033Component } from "./content-module-a033/content-module-a033.component";
import { ContentModuleA040Component } from "./content-module-a040/content-module-a040.component";
import { ContentModuleA050Component } from "./content-module-a050/content-module-a050.component";
import { ContentModuleA051Component } from "./content-module-a051/content-module-a051.component";
import { ContentModuleA060Component } from "./content-module-a060/content-module-a060.component";
import { ContentModuleA061Component } from "./content-module-a061/content-module-a061.component";
import { ContentModuleA062Component } from "./content-module-a062/content-module-a062.component";
import { ContentModuleA070Component } from "./content-module-a070/content-module-a070.component";
import { ContentModuleA080Component } from "./content-module-a080/content-module-a080.component";
import { ContentModuleA081Component } from "./content-module-a081/content-module-a081.component";
import { ContentModuleA082Component } from "./content-module-a082/content-module-a082.component";
import { ContentModuleA090Component } from "./content-module-a090/content-module-a090.component";
import { ContentModuleA091Component } from "./content-module-a091/content-module-a091.component";
import { ContentModuleA092Component } from "./content-module-a092/content-module-a092.component";
import { ContentModuleA100Component } from "./content-module-a100/content-module-a100.component";
import { ContentModuleA110Component } from "./content-module-a110/content-module-a110.component";
import { ContentModuleA111Component } from "./content-module-a111/content-module-a111.component";
import { ContentModuleB010Component } from "./content-module-b010/content-module-b010.component";
import { ContentModuleBaseComponent } from "./content-module-base.component";

@Component({
  selector: "app-content-module",
  template: ` <ng-template appModuleLayoutHost></ng-template>`,
})
export class ContentModuleComponent implements OnInit, OnDestroy {
  @Input() contentModule: ContentModule;
  @ViewChild(ContentModuleDirective, { static: true })
  appModuleLayoutHost: ContentModuleDirective;
  interval: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.createComponent();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  createComponent(): void {
    let component;

    switch (this.contentModule.layout) {
      case "moduleA010":
        component = ContentModuleA010Component;
        break;
      case "moduleA011":
        component = ContentModuleA011Component;
        break;
      case "moduleA020":
        component = ContentModuleA020Component;
        break;
      case "moduleA030":
        component = ContentModuleA030Component;
        break;
      case "moduleA031":
        component = ContentModuleA031Component;
        break;
      case "moduleA032":
        component = ContentModuleA032Component;
        break;
      case "moduleA033":
        component = ContentModuleA033Component;
        break;
      case "moduleA040":
        component = ContentModuleA040Component;
        break;
      case "moduleA050":
        component = ContentModuleA050Component;
        break;
      case "moduleA051":
        component = ContentModuleA051Component;
        break;
      case "moduleA060":
        component = ContentModuleA060Component;
        break;
      case "moduleA061":
        component = ContentModuleA061Component;
        break;
      case "moduleA062":
        component = ContentModuleA062Component;
        break;
      case "moduleA070":
        component = ContentModuleA070Component;
        break;
      case "moduleA080":
        component = ContentModuleA080Component;
        break;
      case "moduleA081":
        component = ContentModuleA081Component;
        break;
      case "moduleA082":
        component = ContentModuleA082Component;
        break;
      case "moduleA090":
        component = ContentModuleA090Component;
        break;
      case "moduleA091":
        component = ContentModuleA091Component;
        break;
      case "moduleA092":
        component = ContentModuleA092Component;
        break;
      case "moduleA100":
        component = ContentModuleA100Component;
        break;
      case "moduleA110":
        component = ContentModuleA110Component;
        break;
      case "moduleA111":
        component = ContentModuleA111Component;
        break;
      case "moduleB010":
        component = ContentModuleB010Component;
        break;
      default:
        component = ContentModuleA010Component;
    }

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);

    const containerRef = this.appModuleLayoutHost.viewContainerRef;

    containerRef.clear();

    const componentRef =
      containerRef.createComponent<ContentModuleBaseComponent>(
        componentFactory,
      );

    componentRef.instance.contentModule = this.contentModule;
  }
}
