import { RouteName } from './routes';

export const seoRules = {
  notFound: {
    me: {
      title: 'Stranica nije pronađena',
      seo_title: 'Stranica nije pronađena',
      seo_description: 'Stranica koju ste tražili ne postoji na sistemu. Moguće je da je u međuvremenu obrisana, preimenovana ili privremeno nedostupna.',
      seo_fb_title: 'RBCG - Stranica nije pronađena',
      seo_fb_description: 'Stranica koju ste tražili ne postoji na sistemu. Moguće je da je u međuvremenu obrisana, preimenovana ili privremeno nedostupna.',
      seo_fb_image_url: '/assets/img/seo/404_SEO_FB_1200x630_LAT.jpg',
      seo_fb_type: 'website',
      seo_tw_title: 'RBCG - Stranica nije pronađena',
      seo_tw_description: 'Stranica koju ste tražili ne postoji na sistemu. Moguće je da je u međuvremenu obrisana, preimenovana ili privremeno nedostupna.',
      seo_tw_image_url: '/assets/img/seo/404_TW_1200x675_LAT.jpg',
      seo_keywords: '404',
      description: 'Stranica koju ste tražili ne postoji na sistemu. Moguće je da je u međuvremenu obrisana, preimenovana ili privremeno nedostupna.',
      route_name: RouteName.NOT_FOUND,
      robots: 'noindex, follow',
      url: ''
    },
    en: {
      title: 'Page not found',
      seo_title: 'Page not found',
      seo_fb_title: 'RBCG - Page not found',
      seo_fb_description: 'The page you requested does not exist on the system. It may have been deleted, renamed or temporarily unavailable.',
      seo_fb_image_url: '/assets/img/seo/404_SEO_FB_1200x630_ENG.jpg',
      seo_description: 'The page you requested does not exist on the system. It may have been deleted, renamed or temporarily unavailable.',
      seo_tw_title: 'RBCG - Page not found',
      seo_tw_description: 'The page you requested does not exist on the system. It may have been deleted, renamed or temporarily unavailable.',
      seo_tw_image_url: '/assets/img/seo/404_TW_1200x675_ENG.jpg',
      seo_fb_type: 'website',
      seo_keywords: '404',
      description: 'The page you requested does not exist on the system. It may have been deleted, renamed or temporarily unavailable.',
      route_name: RouteName.NOT_FOUND,
      robots: 'noindex, follow',
      url: ''
    }
  }
};
