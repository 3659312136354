import { Component, Input } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';

@Component({
  selector: 'app-content-module-a011',
  templateUrl: './content-module-a011.component.html',
  styleUrls: ['./content-module-a011.component.scss']
})
export class ContentModuleA011Component {
  @Input() contentModule: ContentModule;
}
