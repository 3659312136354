import { Component, Input } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a60',
  templateUrl: './slot-a60.component.html',
  styleUrls: ['./slot-a60.component.scss']
})
export class SlotA60Component {
  @Input() slotable: Slot;
  faArrowRight = faArrowRight;
}
