import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import Swiper, { Navigation, Pagination } from "swiper";
import { ContentModule } from "../../models/content-module.model";
import { ContentModuleBaseComponent } from "../content-module-base.component";

@Component({
  selector: "app-content-module-a062",
  templateUrl: "./content-module-a062.component.html",
})
export class ContentModuleA062Component
  implements ContentModuleBaseComponent, OnInit, AfterViewInit
{
  @Input() contentModule: ContentModule;
  isMobile: boolean = null;

  constructor(@Inject(PLATFORM_ID) public platformId) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth <= 768;
      window.onresize = () => {
        this.isMobile = window.innerWidth <= 768;
        if (this.isMobile) {
          this.initSlider();
        }
      };
    }
  }

  ngAfterViewInit(): void {
    if (this.isMobile) {
      if (isPlatformBrowser(this.platformId)) {
        this.initSlider();
      }
    }
  }

  initSlider(): void {
    const swiper = new Swiper(".swiper-module-a62", {
      modules: [Navigation, Pagination],
      loop: true,
      watchOverflow: true,
      slidesPerView: 1.25,
      spaceBetween: 16,
      autoHeight: false,
      centeredSlides: true,
      navigation: {
        nextEl: ".nav-right-a62",
        prevEl: ".nav-left-a62",
      },
      pagination: {
        el: ".swiper-pagination-a62",
        clickable: true,
      },
    });
  }
}
