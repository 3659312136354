<div class="fixed top-0 w-full border-b border-neutral-100 bg-neutral-white h-15">
  <div
    class="z-50 relative container flex items-center justify-between bg-neutral-white border-t border-neutral-white py-1">
    <a [routerLink]="['/']" class=""
       title="Početna"
       i18n-title="@@IRF_Header_Homepage_Link">
      <img src="assets/img/logos/rbcg-logo-mobile.png" class="w-[196px]" width="587" height="144" alt="RBCG"/>
    </a>
    <div class="flex items-center py-2 text-24 text-neutral-800">
      <a [routerLink]="[searchRoute]"
         title="Pretraga"
         i18n-title="@@IRF_Header_Search_Link"
         class="block mr-6 text-neutral-800 leading-0">
        <fa-icon [icon]="['fal', 'magnifying-glass']"></fa-icon>
      </a>
      <button (click)="toggleMenu()">
        <fa-icon *ngIf="!menuVisible" [icon]="['fal', 'bars']"></fa-icon>
        <fa-icon *ngIf="menuVisible" [icon]="['fal', 'xmark-large']"></fa-icon>
      </button>
    </div>
  </div>

  <div class="z-40 sticky top-[-56px] w-full">
    <div class="menu -translate-y-full opacity-0"
         [ngClass]="{'translate-y-0 opacity-100' : menuVisible,
       '-translate-y-full opacity-0' : !menuVisible}">
      <div class="z-40 container h-full">
        <div class="flex flex-col justify-between h-full">
          <div class="flex flex-col my-8">
            <button *ngFor="let menuItem of headerMenu, let i = index"
                    class="w-full py-4 flex justify-between border-t items-center"
                    [ngClass]="{ 'border-neutral-100' : i !== 0, 'border-neutral-white' : i === 0 }"
                    (click)="onMenuItemClick(i, menuItem.link)"
                    [attr.aria-label]="menuItem.title || 'Menu item'">
              <span class="text-18 text-neutral-900 font-700 leading-150">
                {{ menuItem.title }}
              </span>
              <fa-icon *ngIf="menuItem.items?.length > 0" [icon]="['fas', 'chevron-right']" class="text-12" [attr.aria-label]="'chevron right'"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let menuItem of headerMenu, let i = index">
      <div *ngIf="menuItem.items?.length > 0"
           class="submenu"
           [ngClass]="{'translate-x-0 opacity-100' : submenuVisible[i],
         '-translate-x-full opacity-0' : !submenuVisible[i]}">
        <div class="container h-full">
          <div class="flex flex-col justify-between h-full">
            <div class="flex flex-col">
              <button class="sticky top-0 w-full flex py-6 bg-neutral-white border-b border-neutral-100"
                      (click)="closeSubmenu(i)"
                      title="{{ menuItem.title }}"
                      [attr.aria-label]="menuItem.title || 'Submenu item'">
                <fa-icon [icon]="['fal', 'arrow-left']" class="text-24 mr-4"></fa-icon>
                <span class="text-24 text-neutral-900 font-700 leading-125">
                  {{ menuItem.title }}
                </span>
              </button>
              <div *ngFor="let submenuItem of menuItem.items, let i = index"
                   class="w-full pt-4 border-t"
                   [ngClass]="{'border-neutral-white' : i === 0, 'border-neutral-100' : i !== 0}">
                <span class="block text-18 text-neutral-900 font-700 leading-150">
                  {{ submenuItem.title }}
                </span>
                <a *ngFor="let subSubmenuItem of submenuItem.items"
                   class="block w-full p-4 text-left"
                   title="{{ subSubmenuItem.title }}"
                   [href]="[subSubmenuItem.url]">
                  <span class="text-16 leading-125">
                    {{ subSubmenuItem.title }}
                  </span>
                </a>
              </div>
            </div>
            <div class="py-8 mx-auto flex justify-between text-16 text-neutral-800 leading-125">
              <a *ngIf="routeTranslations.me" [href]="routeTranslations.me"
                 title="Crnogorski"
                 i18n-title="@@IRF_Header_Montenegrin_Link"
                 [ngClass]="{'font-700' : locale === 'me'}">
                MNE
              </a>
              <span *ngIf="routeTranslations.me && routeTranslations.en" class="mx-4">|</span>
              <a *ngIf="routeTranslations.en" [href]="routeTranslations.en"
                 title="Engleski"
                 i18n-title="@@IRF_Header_English_Link"
                 [ngClass]="{'font-700' : locale === 'en'}">
                ENG
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
