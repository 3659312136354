<section class="relative md:container md:w-full md:rounded-48 py-6 md:py-12"
         [ngClass]="{'bg-deep-blue': contentModule.is_highlighted }"
         style="color: var(--page-accent);">
  <header *ngIf="!contentModule.name_hidden"
          class="container grid gap-4 grid-cols-6 md:grid-cols-24">
    <div class="col-span-6 md:col-span-14 md:col-start-6 md:mb-12 text-left md:text-center  space-y-4">
      <h1 
      class="text-24 md:text-30 font-500 font-montserrat"
      [ngClass]="contentModule.is_highlighted ? 'text-neutral-white' : 'text-neutral-900'"
      >{{ contentModule.name }}</h1>
      <p class="text-16 md:text-18 font-400 text-neutral-800 leading-155" *ngIf="contentModule.description"
      [ngClass]="contentModule.is_highlighted ? 'text-neutral-400' : 'text-neutral-800'">{{ contentModule.description }}
      </p>
    </div>
  </header>
  <div class="container grid gap-4 grid-cols-6 md:grid-cols-24 mt-6">
    <div class="col-span-6 md:col-span-14 md:col-start-6">
      <div class="flex flex-row flex-wrap-reverse md:flex-wrap gap-4 justify-center space-y-4 md:space-y-0">
        <div *ngFor="let slotable of contentModule.slotables, let index = index"
          class="w-full md:w-auto">
          <app-slot-a90
            [slotable]="slotable"
            [index]="index"
            [isHighlighted]="contentModule.is_highlighted"
            [count]="contentModule.slotables.length"
          ></app-slot-a90>
        </div>
      </div>
    </div>
  </div>
</section>
