import { Component, Input } from "@angular/core";
import { ContentModule } from "../../models/content-module.model";
import { ContentModuleBaseComponent } from "../content-module-base.component";

@Component({
  selector: "app-content-module-a082",
  templateUrl: "./content-module-a082.component.html",
  styleUrls: ["./content-module-a082.component.scss"],
})
export class ContentModuleA082Component implements ContentModuleBaseComponent {
  @Input() contentModule: ContentModule;
}
