import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
} from "@angular/core";
import Swiper, { Navigation, Pagination } from "swiper";
import { ContentModule } from "../../models/content-module.model";
import { ContentModuleBaseComponent } from "../content-module-base.component";

@Component({
  selector: "app-content-module-a060",
  templateUrl: "./content-module-a060.component.html",
})
export class ContentModuleA060Component
  implements ContentModuleBaseComponent, AfterViewInit
{
  @Input() contentModule: ContentModule;

  constructor(@Inject(PLATFORM_ID) public platformId) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const swiper = new Swiper(".swiper-module-a60", {
        modules: [Navigation, Pagination],
        loop: this.contentModule?.slotables?.length > 1,
        watchOverflow: true,
        slidesPerView: 1.25,
        spaceBetween: 16,
        autoHeight: false,
        centeredSlides: true,
        navigation: {
          nextEl: ".nav-right-a60",
          prevEl: ".nav-left-a60",
        },
        pagination: {
          el: ".swiper-pagination-a60",
          clickable: true,
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
            centeredSlides: false,
          },
          1136: {
            slidesPerView: 4,
            spaceBetween: 16,
            centeredSlides: false,
          },
        },
      });
    }
  }
}
