<section class="max-w-max relative text-gray-300 mx-auto">
  <div class="container flex items-center justify-center py-20 md:py-48">
    <div class="flex flex-col justify-center items-center h-full relative">
      <h1 class="text-56 sm:text-80 font-mono font-bold mb-6 leading-100">404</h1>
      <p class="text-18 sm:text-24 mb-6" i18n="@@404Copy">Žao nam je, sadržaj koji ste tražili nije pronađen.</p>
      <p class="text-18 sm:text-24">
        <span>
          <span i18n="@@404CopyGoBack">Vratite se na</span>
          <a href="/" class="text-lima px-2 hover:underline text-deep-blue" i18n="@@404CopyLinkHome">naslovnu</a>
          <span i18n="@@404Copy_or">ili nas</span>
          <a [appFlRouterLink]="['contact']" class="text-lima pl-2 hover:underline text-deep-blue" i18n="@@404CopyLinkContact">kontaktirajte</a>.
        </span>
      </p>
    </div>
  </div>
</section>
