<section class="flex flex-col gap-6 md:gap-12 md:container pt-12 pb-5 md:py-12 md:rounded-48"
         [ngClass]="{'bg-deep-blue': contentModule.is_highlighted}"
         style="color: var(--page-accent);"
>
    <header *ngIf="!contentModule.name_hidden"
      class="w-full px-4 md:container block md:px-[8%]">
      <h1 
        class="text-28 md:text-30 lg:text-42 font-500 font-montserrat"
        [ngClass]="contentModule.is_highlighted ? 'text-neutral-white' : 'text-neutral-900'"
        >{{ contentModule.name }}</h1>
    </header>
    <div class="w-full pl-0 md:pl-2 md:container">
      <div class="md:px-[8%] w-full md:grid md:gap-4 md:grid-cols-24">
        <div *ngFor="let slotable of contentModule.slotables"
            class="col-span-6 md:col-span-8 slot-a80">
          <app-slot-a80 [slotable]="slotable" [isHighlighted]="contentModule.is_highlighted"></app-slot-a80>
        </div>
      </div>
    </div>
</section>
