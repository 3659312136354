<section class="flex flex-col gap-6 md:gap-12 md:container px-4 md:px-12 py-8 md:py-12 md:rounded-48"
         style="color: var(--page-accent);"
         [ngClass]="{'bg-deep-blue': contentModule.is_highlighted}"
>
    <header *ngIf="!contentModule.name_hidden" class="block w-full md:container">
      <h1 class="text-24 md:text-36 font-500  font-montserrat" [ngClass]="contentModule.is_highlighted ? 'text-neutral-white' : 'text-neutral-900 md:text-black'">{{ contentModule.name }}</h1>
    </header>
    <div class="w-full md:container grid gap-4 md:gap-5 grid-cols-1 md:grid-cols-24">
      <div *ngFor="let slotable of contentModule.slotables" class=" col-span-1 md:col-span-12 lg:col-span-8">
        <app-slot-a70 [slotable]="slotable"></app-slot-a70>
      </div>
    </div>
</section>
