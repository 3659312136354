import { Component, Input } from "@angular/core";
import { Slot } from "../../models/slot.model";

@Component({
  selector: "app-slot-a082",
  templateUrl: "./slot-a082.component.html",
  styles: [],
})
export class SlotA082Component {
  @Input() slotable: Slot;
}
