import { Component, Input } from "@angular/core";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { Slot } from "../../models/slot.model";

@Component({
  selector: "app-slot-a32",
  templateUrl: "./slot-a32.component.html",
  styleUrls: ["./slot-a32.component.scss"],
})
export class SlotA32Component {
  @Input() slotable: Slot;
  @Input() isHighlihted: boolean;
  faArrowRight = faArrowRight;
}
