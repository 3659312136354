import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { MenuService } from '../../../@modules/menu/services/menu.service';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { GlobalService } from '../../../Library/services/global.service';
import { LocaleService, RouteTranslation } from '../../../Library/services/locale.service';

@Component({
  selector: "app-header-desktop",
  templateUrl: "./header-desktop.component.html",
  styleUrls: ["./header-desktop.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderDesktopComponent implements OnInit, OnDestroy, OnChanges {
  @Input() headerMenu: MenuItem[];
  @ViewChild("header") header: ElementRef;
  @ViewChild("logo") logo: ElementRef;

  constructor(
    private localeService: LocaleService,
    private menuService: MenuService,
    private globalService: GlobalService,
    private detailService: DetailService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public localeCode,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.locale = this.localeService.getLocale();
    this.localeService.currentRouteTranslations$.subscribe(
      (routeTranslations: RouteTranslation) => {
        this.routeTranslations = routeTranslations;
      }
    );
  }

  private readonly SCROLL_THRESHOLD = 100;

  @HostListener("window:scroll") onWindowScroll(): void {
    if (this.document.documentElement.scrollTop >= this.SCROLL_THRESHOLD && this.header?.nativeElement?.classList.contains('py-4')) {
      this.shrinkHeader();
    } else if (
      this.document.documentElement.scrollTop < this.SCROLL_THRESHOLD &&
      this.header?.nativeElement?.classList.contains("py-2")
    ) {
      this.spreadHeader();
    }
  }

  routeTranslations: RouteTranslation;
  locale: string;
  searchRoute: string = `${routesTrans[currentLocale].search}`;
  ngUnsubscribe = new Subject();
  headerItemActive: boolean[];
  anyHeaderItemActive: boolean = false;
  localeDropdownVisible: boolean = false;


  ngOnChanges(): void {
    if (this.headerMenu) {
      this.headerItemActive = [];
      for (const item of this.headerMenu) {
        this.headerItemActive.push(false);
      }
    }
  }

  ngOnInit(): void {
    this.setEscapeListener();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public shrinkHeader(): void {
    this.header?.nativeElement?.classList.remove("py-4");
    this.header?.nativeElement?.classList.add("py-2");
    this.logo.nativeElement.style.width = "218px";
    this.logo.nativeElement.style.height = "48px";
  }

  public spreadHeader(): void {
    this.header?.nativeElement?.classList.remove("py-2");
    this.header?.nativeElement?.classList.add("py-4");
    this.logo.nativeElement.style.width = "254px";
    this.logo.nativeElement.style.height = "56px";
  }

  onHeaderItemOver(index: number): void {
    this.headerItemActive[index] = true;
    this.anyHeaderItemActive = true;
  }

  onHeaderItemLeave(index: number): void {
    this.headerItemActive[index] = false;
    this.anyHeaderItemActive = false;
  }

  toggleLocaleMenu(): void {
    this.localeDropdownVisible = !this.localeDropdownVisible;
  }

  setEscapeListener(): void {
    if (isPlatformBrowser(this.platformId)) {
      const escapeListener = this.renderer.listen(
        document,
        "keydown.escape",
        () => {
          this.localeDropdownVisible = false;
        }
      );
    }
  }

  redirectToHome(locale: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    location.href = environment.websiteUrl + "/" + locale;
  }

  chooseLanguage(locale: string): void {
    this.redirectToHome(locale);
  }
}
