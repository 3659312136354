import { Component, Input } from "@angular/core";
import { ContentModule } from "../../models/content-module.model";
import { ContentModuleBaseComponent } from "../content-module-base.component";

@Component({
  selector: "app-content-module-a081",
  templateUrl: "./content-module-a081.component.html",
  styles: [],
})
export class ContentModuleA081Component implements ContentModuleBaseComponent {
  @Input() contentModule: ContentModule;
}
