<section class="relative w-full max-w-max md:mx-auto pt-6 pb-8 md:py-16 overflow-hidden md:px-[6%]"
         style="color: var(--page-accent);">
  <div class="bg-deep-blue absolute px-4 md:px-0 md:mx-0 lg:rounded-48 inset-x-0 h-[324px] lg:h-[264px] top-0 z-10"></div>
  <header class="px-4 md:px-0 md:container w-full flex flex-row items-center justify-between mb-4 lg:mb-12 relative z-20">

    <h1 class="flex-1 text-24 md:text-42 text-center md:text-left font-500 text-neutral-white font-montserrat" *ngIf="!contentModule.name_hidden" >
      {{ contentModule.name }}
    </h1>

    <div class="flex-row items-center space-x-2 hidden md:flex z-20 relative">
      <button class="w-10 h-10 text-neutral-400 hover:text-gold-400 hover:cursor-pointer transition-colors ease-in-out nav-left-a61"
      >
        <fa-icon class="text-24" [icon]="['fal', 'arrow-left']" [attr.aria-label]="'arrow left'"></fa-icon>
      </button>
      <button class="w-10 h-10 text-neutral-400 hover:text-gold-400 hover:cursor-pointer transition-colors ease-in-out nav-right-a61"
      >
        <fa-icon class="text-24" [icon]="['fal', 'arrow-right']" [attr.aria-label]="'arrow right'"></fa-icon>
      </button>
    </div>
  </header>

  <div class="w-full md:container">
    <div class="swiper swiper-module-a61 !z-10 ">
      <div class="swiper-wrapper p-1 flex flex-row">
        <div class="swiper-slide pb-4 h-full" style="flex-grow: 1" *ngFor="let slotable of contentModule.slotables">
          <app-slot-a60 [slotable]="slotable"></app-slot-a60>
        </div>
      </div>
    </div>
  </div>
</section>
