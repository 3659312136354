<div [ngClass]="{'text-neutral-white': isHighglighed, 'text-neutral-900': !isHighglighed}">
  <h2 class="text-24 md:text-28 lg:text-30 font-500 border-l-8 lg:border-none pl-3 lg:pl-0 lg:pr-8 mb-4 font-montserrat"
  [ngClass]="{'text-neutral-white': isHighglighed, 'text-neutral-900': !isHighglighed}"
  [style.border-color]="!isHighglighed ? '#002157' : 'white'">
    {{ slotable.title }}
  </h2>
  <p class="text-16 pl-5 lg:pl-0 md:text-14 lg:text-18 mb-8 lg:pr-6"
  [ngClass]="{'text-neutral-400': isHighglighed, 'text-neutral-800': !isHighglighed}"
     [innerHTML]="slotable.description"></p>
  <a *ngIf="slotType !== 'custom-html'"
     title="{{ slotable.title }}" [appFlLink]="slotable.url"
     class="btn btn__primary md:inline-flex">
    <span i18n="@@general_learn_more_btn">Saznajte više</span>
    <fa-icon [icon]="['fal', 'arrow-right']" class="ml-2"></fa-icon>
  </a>
</div>
