import { Component, Input, OnInit } from "@angular/core";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { MenuItem } from "src/app/@modules/menu/models/menu-item.model";
import { LocaleService } from "src/app/Library/services/locale.service";

@Component({
  selector: "app-footer-mobile",
  templateUrl: "./footer-mobile.component.html",
  styles: [],
})
export class FooterMobileComponent implements OnInit {
  @Input() footerMenu: MenuItem[];
  currentYear: number;
  locale: string;

  faFacebook = faFacebook;
  faXTwitter = faSquareXTwitter;
  faLinkedin = faLinkedin;
  faInstagram = faInstagram;

  constructor(private localeService: LocaleService) {
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
