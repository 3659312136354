import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a30',
  templateUrl: './slot-a30.component.html',
  styleUrls: ['./slot-a30.component.scss']
})
export class SlotA30Component implements OnInit {

  @Input() slotable: Slot;
  @Input() slotType: string;
  @Input() isHighglighed: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }
}
