<section
  class="relative w-full flex flex-col justify-end md:justify-center min-h-[582px] md:min-h-[448px] max-w-max py-10 md:rounded-48 overflow-hidden"
  [ngClass]="{
    'bg-azure-100': !contentModule.image,
    'bg-gradient-hero': contentModule.image
  }"
  style="color: var(--page-accent)"
>
  <img
    *ngIf="contentModule.image"
    class="absolute inset-0 object-center object-cover w-full h-full z-10 mix-blend-multiply rounded-24 lg:rounded-48"
    width="1920"
    height="480"
    srcset="{{ contentModule.image?.i_390x585 }} 390w, {{ contentModule.image?.i_1920x480 }} 1920w"
    sizes="(max-width: 768px) 390px, (min-width: 768px) 1920px"
    alt="{{ contentModule.name }}"
    title="{{ contentModule.name }}"
    [src]="contentModule.image?.i_1920x480"
  />
  <div
    *ngIf="!contentModule.image"
    class="bg-deep-blue w-full h-full absolute inset-0"
  ></div>

  <div *ngIf="contentModule.image" class="absolute inset-0 bg-[#080F1A] bg-opacity-40 lg:bg-opacity-50">

  </div>

  <div
    class="container grid grid-cols-6 md:grid-cols-24 md:gap-4 relative h-full items-center z-30"
  >
    <div
      class="col-span-6 md:col-start-3 md:col-span-10 md:col-end-13"
    >
      <h3
        *ngIf="!contentModule.name_hidden"
        class="text-14 md:text-16 text-neutral-white uppercase mb-2 font-montserrat"
      >
        {{ contentModule.name }}
      </h3>
      <div *ngFor="let slotable of contentModule.slotables">
        <app-slot-a20
          [slotable]="slotable"
          [slotType]="contentModule.slot_type"
        ></app-slot-a20>
      </div>
    </div>
  </div>
</section>
