import { Component, Input } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a50',
  templateUrl: './slot-a50.component.html',
  styleUrls: ['./slot-a50.component.scss']
})
export class SlotA50Component {

  @Input() slotable: Slot;
}
