<section class="relative md:w-full md:container md:rounded-48 py-6 md:py-12"
        [ngClass]="{'bg-deep-blue': contentModule.is_highlighted }"
        style="color: var(--page-accent);">
    <div class="container grid md:gap-4 md:grid-cols-24 space-y-4 md:space-y-0">
      <div *ngFor="let slotable of contentModule.slotables, let even = even"
           class="col-span-6 md:col-span-9"
        [ngClass]="even ? 'md:col-start-3' : ' md:col-start-14'">
        <app-slot-a92 [slotable]="slotable"[isHighlighted]="contentModule.is_highlighted"></app-slot-a92>
      </div>
    </div>
</section>
