import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { DateTime } from "luxon";
import { Media, MediaAdapter } from "../../../Library/media/models/media.model";
import { Resource } from "../../../Library/resource/resource.model";
import { currentLocale, routes as routesTrans } from "../../../Library/routes";

export class Slot extends Resource<Request> {
  public id: number;
  public name: string;
  public title: string;
  public description?: string;
  public slug: string;
  public path: string;
  public url: string;
  public image: Media;
  public icon: string;
  public locale: string;
  public published_at?: string;
  public publishedAtDate?: Date;
  public color?: string;
  public article_type_label?: string;
  public loan_category_color?: string;
  public loan_category_title?: string;
  public main_attribute_key?: string;
  public main_attribute_value?: string;
  public supertitle?: string;
  public intro_content?: string;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: "root",
})
export class SlotAdapter {
  constructor(
    private mediaAdapter: MediaAdapter,
    @Inject(LOCALE_ID) private localeCode: string,
  ) {}

  adapt(data: any, slot_type?: string): Slot {
    const articleRoute = `/${routesTrans[currentLocale].articleDetail}`;
    const loanRoute = `/${routesTrans[currentLocale].loanDetail}`;

    const item = data.data;

    switch (this.localeCode) {
      case "en":
        item.locale = "en";
        break;
      case "sr-Latn-ME":
        item.locale = "me";
        break;
    }

    switch (slot_type) {
      case "article":
        item.url = articleRoute + "/" + item.slug;
        switch (item.article_type_label) {
          case "news": {
            item.supertitle = $localize`:@@IRF_Article_Type:Novosti`;
            break;
          }
          case "biography": {
            item.supertitle = $localize`:@@IRF_Article_Type:Biografija`;
            break;
          }
          case "text-page": {
            item.supertitle = $localize`:@@IRF_Article_Type:Tekstualna Stranica`;
            break;
          }
        }
        break;
      case "loan":
        item.url = loanRoute + "/" + item.slug;
        item.supertitle = item.loan_category_title;
        break;
    }

    if (item.seo_image) {
      item.image = item.seo_image;
    } else if (item.image && !item.seo_image) {
      item.image = item.image;
    } else {
      item.image = new Media({
        i_1920x1440: `/assets/img/default/default_${currentLocale}_1920x1440.jpg`,
        i_1920x480: `/assets/img/default/default_${currentLocale}_1920x480.jpg`,
        i_904x678: `/assets/img/default/default_${currentLocale}_904x678.jpg`,
        i_390x292: `/assets/img/default/default_${currentLocale}_390x292.jpg`,
        i_272x204: `/assets/img/default/default_${currentLocale}_272x204.jpg`,
        i_106x81: `/assets/img/default/default_${currentLocale}_106x81.jpg`,
        i_390x585: `/assets/img/default/default_${currentLocale}_390x585.jpg`,
        i_780x1170: `/assets/img/default/default_${currentLocale}_780x1170.jpg`,
        i_1200x675: `/assets/img/default/default_${currentLocale}_1200x675.jpg`,
        i_1200x630: `/assets/img/default/default_${currentLocale}_1200x630.jpg`,
        caption: item.title,
      });
    }

    if (item.seo_title) {
      item.title = item.seo_title;
    }

    if (item.name && !item.title) {
      item.title = item.name;
    }

    if (item.image) {
      item.image = this.mediaAdapter.adapt({ data: item.image });
    }

    if (item.published_at) {
      item.publishedAtDate = DateTime.fromSQL(item.published_at, {
        zone: "UTC",
      }).toJSDate();
    }

    return new Slot(item);
  }

  adaptArray(items: any, slot_type?: string): Slot[] {
    items = items.data.data ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({ data: item }, slot_type);
    });
  }
}
