<div class="bg-neutral-white border-t-8 border-t-azure-300 pt-6 pb-4 border-b border-b-neutral-900 sticky top-0 z-[100]">
  <div class="container flex flex-row justify-between items-center">
    <div class="flex flex-1 flex-row items-center">
      <img src="/assets/img/logos/logo-desktop.png" alt="RBCG" width="512" height="455" class="w-[58px] h-[52px] mr-8">
      <h2 class="text-24 md:text-26 text-neutral-black uppercase font-700">Design system</h2>
    </div>
    <select id="components" name="components" [(ngModel)]="activeSection">
      <option *ngFor="let section of sections" [ngValue]="section.value">
            {{ section.label }}
      </option>
    </select>
  </div>
</div>

<div class="py-12">
  <app-ds-buttons *ngIf="!activeSection || activeSection == 1"></app-ds-buttons>
  <app-ds-input-fields *ngIf="!activeSection || activeSection == 2"></app-ds-input-fields>
  <app-ds-icons *ngIf="!activeSection || activeSection == 3"></app-ds-icons>


</div>


