<section class="relative w-full overflow-hidden pt-6 pb-4 lg:py-12 md:rounded-48 md:px-[6%]"
          [ngClass]="{'bg-deep-blue': contentModule.is_highlighted }"
          style="color: var(--page-accent);">
  <header *ngIf="!contentModule.name_hidden" class="px-4 md:px-0 md:container block w-full mb-4 lg:mb-12">
    <h1 class="text-24 md:text-42 font-500 text-center md:text-left font-montserrat"
    [ngClass]="contentModule.is_highlighted ? 'text-neutral-white' : 'text-neutral-900'">
      {{ contentModule.name }}
    </h1>
  </header>

  <div *ngIf="!isMobile"
       class="w-full !pl-4 md:!pl-0 md:container flex flex-row overflow-hidden md:overflow-visible md:grid md:gap-0 md:grid-cols-24 pb-4">
      <div class="pr-4 pb-4 md:col-span-8 lg:col-span-6" style="flex-grow: 1" *ngFor="let slotable of contentModule.slotables">
        <app-slot-a60 [slotable]="slotable"></app-slot-a60>
      </div>
  </div>
<div *ngIf="isMobile"
       class="w-full !pl-4 md:!pl-0 md:container flex flex-row overflow-hidden md:overflow-visible swiper swiper-module-a62 !z-10 pb-4">
    <div class="swiper-wrapper md:!grid md:gap-0 md:grid-cols-24">
      <div class="swiper-slide pb-4 h-full md:!col-span-8 lg:!col-span-6" style="flex-grow: 1" *ngFor="let slotable of contentModule.slotables">
        <app-slot-a60 [slotable]="slotable"></app-slot-a60>
      </div>
    </div>
  </div>
</section>
