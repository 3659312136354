<div
  class="w-full relative lg:rounded-48 overflow-hidden min-h-[582px] md:min-h-[560px]"
>
  <img
    *ngIf="slotable.image"
    [src]="slotable.image.i_1920x1440"
    class="object-cover object-center absolute inset-0 w-full h-full"
    [alt]="slotable.image?.title"
  />
  <div
    class="absolute inset-0 w-full slot-a011-gradient flex items-end z-10 px-4 pb-8 lg:p-12"
  >
    <div class="flex flex-col gap-2 items-start justify-end md:max-w-half">
      <h1
        *ngIf="slotable.title && !nameHidden"
        class="font-500 text-24 lg:text-68 font-montserrat line-clamp-3 text-neutral-white leading-100"
      >
        {{ slotable.title }}
      </h1>
      <span
        *ngIf="slotable.intro_content"
        class="text-neutral-white font-500 text-16 lg:text-18 leading-120 max-w-[628px]"
      >
        {{ slotable.intro_content }}
      </span>
      <a
        *ngIf="slotable.url"
        [appFlLink]="slotable.url"
        class="btn btn__primary self-stretch md:self-start mt-6"
        i18n="@@read_more"
      >
        Pročitaj više
      </a>
    </div>
  </div>
</div>
