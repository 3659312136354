<div class="relative h-full group rounded-24 overflow-hidden">
  <a [appFlLink]="slotable.url" style="color:var(--page-accent);"
    class="flex items-end h-full w-full">
    <img class="flex m-none absolute inset-0 object-cover object-center w-full h-full"
         [src]="slotable.image.i_390x585"
         [alt]="slotable.title"
         loading="lazy">
    <div class="m-3 w-full flex border-2 border-neutral-100 group-hover:border-gold-400 rounded-20 overflow-hidden px-4 py-6 text-neutral-white bg-neutral-900/40 z-10">
      <h3 class="group-hover:underline uppercase text-20 md:text-22 lg:text-22 font-700 z-20 relative w-full">
        {{ slotable.title }}
      </h3>
    </div>
  </a>
</div>
