<header class="group z-100 fixed top-0 transition-all duration-200 ease-in-out w-full border-b border-neutral-100 pointer-events-none py-4 bg-neutral-white" #header>
  <div
    class="z-100 w-full transition-all origin-left duration-200 ease-in">
    <div class="container h-full flex items-center justify-between relative">
      <a routerLink="/" class="w-[256px] h-[56px] z-100 pointer-events-auto relative hover:cursor-pointer transition-all duration-200 ease-in-out" #logo>
        <img class="block" src="assets/img/logos/logo-desktop.png" width="508" height="112" title="Početna" i18n-title="@@IRF_Header_Homepage_Link" alt="Razvojna Banka Crne Gore"/>
      </a>
      <div class="flex h-full">
      <a *ngFor="let headerItem of headerMenu, let i = index"
         title="{{ headerItem.title }}"
         (mouseenter)="onHeaderItemOver(i)"
         (mouseleave)="onHeaderItemLeave(i)"
         (click)="onHeaderItemLeave(i)"
         [href]="headerItem.url"
         class="pointer-events-auto h-full flex items-center px-4 pt-4 pb-5 text-14 font-500 text-neutral-800 border-transparent transition-colors duration-200 ease-in hover:cursor-pointer"
         [ngClass]="{'bg-neutral-white' : headerItemActive[i]}">
        {{ headerItem.title }}
      </a>
      <a [routerLink]="[searchRoute]"
         title="Pretraga"
         i18n-title="@@IRF_Header_Search_Link"
         class="pointer-events-auto h-[57px] pt-4 pb-5 text-neutral-800 hover:text-azure-100 hover:bg-neutral-white border-transparent transition-colors duration-200 ease-in flex items-center">
        <div class="w-full h-full border-l border-neutral-400 flex items-center justify-center px-4 text-14">
          <fa-icon [icon]="['fal', 'magnifying-glass']"></fa-icon>
        </div>
      </a>
      </div>
    </div>
  </div>

  <div *ngFor="let headerItem of headerMenu, let i = index"
       class="pointer-events-auto z-50 absolute left-0 right-0 scroll-top-position">
    <div *ngIf="headerItem.items?.length > 0" class="w-full"
         [ngClass]="{'min-h-0': !headerItemActive[i], 'min-h-[334px]' : headerItemActive[i] }">
      <div class="w-full bg-neutral-400 transition-[transform,opacity] origin-top duration-200 ease-out"
           [ngClass]="{'opacity-0 scale-y-0' : !headerItemActive[i], 'opacity-100 scale-y-100': headerItemActive[i]}"
           (mouseenter)="onHeaderItemOver(i)"
           (mouseleave)="onHeaderItemLeave(i)">
        <div class="dropdown-menu transition-all duration-100 ease-in">
          <div class="container relative z-10 flex flex-row">
            <div class="w-5/24 ml-4/24 pt-14 pr-1/24">
              <span class="text-24 leading-125 font-500 text-neutral-900">
                {{ headerItem.title }}
              </span>
            </div>
            <div *ngFor="let childItem of headerItem.items" class="w-5/24 pt-14 pb-10 pr-6">
              <span class="text-18 text-neutral-900 font-500 leading-150">
                {{ childItem.title }}
              </span>
              <div class="mt-5 flex flex-col gap-3">
                <a *ngFor="let grandChildItem of childItem.items"
                   title="{{ grandChildItem.title }}"
                   [href]="grandChildItem.url"
                   (click)="onHeaderItemLeave(i)"
                   class="text-14 leading-125 text-neutral-900 hover:text-azure-100 hover:cursor-pointer transition-text duration-100">
                  {{ grandChildItem.title }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="w-full h-[100vh] z-90 fixed top-0 bg-neutral-white/70 backdrop-blur-[12px] pointer-events-none transition-opacity duration-300"
     [ngClass]="{'opacity-0': !anyHeaderItemActive, 'opacity-100': anyHeaderItemActive}">
</div>
