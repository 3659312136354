import { Component, Input } from "@angular/core";
import { Slot } from "../../models/slot.model";

@Component({
  selector: "app-slot-a011",
  templateUrl: "./slot-a011.component.html",
  styleUrls: ["./slot-a011.component.scss"],
})
export class SlotA011Component {
  @Input() slotable: Slot;
  @Input() nameHidden: boolean;
}
