import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlHighlightDirective } from '../Library/directives/fl-highlight.directive';
import { FlLinkDirective } from '../Library/directives/fl-link.directive';
import { FlRouterLinkActiveDirective } from '../Library/directives/fl-router-link-active.directive';
import { FlRouterLinkDirective } from '../Library/directives/fl-router-link.directive';
import { ImgFallbackDirective } from '../Library/directives/img-falback.directive';
import { FlTooltipDirective } from '../Library/directives/tooltip.directive';
import { FormControlValidationDirective } from '../Library/form/directives/form-control-validation.directive';
import { FormSubmitValidationDirective } from '../Library/form/directives/form-submit-validation.directive';
import { FooterDesktopComponent } from './components/footer-desktop/footer-desktop.component';
import { FooterMobileComponent } from './components/footer-mobile/footer-mobile.component';
import { HeaderDesktopComponent } from './components/header-desktop/header-desktop.component';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DecimalNumberPipe } from './pipes/decimal-number.pipe';
import { FlHighlightPipe } from './pipes/fl-highligh';
import { CheckScriptsPipe, SafeHtmlPipe } from './pipes/safe-html';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  declarations: [
    LoaderComponent,
    FormControlValidationDirective,
    FormSubmitValidationDirective,
    FlRouterLinkDirective,
    FlRouterLinkActiveDirective,
    FlLinkDirective,
    FlHighlightDirective,
    FlHighlightPipe,
    FlTooltipDirective,
    ImgFallbackDirective,
    SafeHtmlPipe,
    TruncatePipe,
    DecimalNumberPipe,
    CheckScriptsPipe,
    HeaderMobileComponent,
    HeaderDesktopComponent,
    FlTooltipDirective,
    FooterMobileComponent,
    FooterDesktopComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    HttpClientJsonpModule
  ],
  exports: [
    LoaderComponent,
    FormControlValidationDirective,
    FormSubmitValidationDirective,
    FlRouterLinkDirective,
    FlRouterLinkActiveDirective,
    FlLinkDirective,
    FlHighlightDirective,
    FlTooltipDirective,
    SafeHtmlPipe,
    FlHighlightPipe,
    TruncatePipe,
    DecimalNumberPipe,
    CheckScriptsPipe,
    ImgFallbackDirective,
    HeaderDesktopComponent,
    HeaderMobileComponent,
    FlTooltipDirective,
    FooterDesktopComponent,
    FooterMobileComponent
  ],
})
export class SharedModule {
}
