<section style="color: var(--page-accent);" class="md:rounded-48 pt-4 pb-8 md:py-12" [ngClass]="{'bg-deep-blue highlighted-module': contentModule.is_highlighted}">

  <header *ngIf="!contentModule.name_hidden" class="w-full container block mb-12">
    <h1 class="text-28 lg:text-42 font-500 md:px-30 font-montserrat capitalize" [ngClass]="contentModule.is_highlighted ? 'text-neutral-white' : 'text-neutral-900'">
      {{ contentModule.name }}
    </h1>
  </header>

  <div class="module-a40 container md:w-full">
    <div class="md:px-12">
      <div class="swiper swiper-module-a40 md:px-12 h-84 md:h-[518px]" style="overflow-x: visible">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let slotable of contentModule.slotables">
            <app-slot-a40 [slotable]="slotable"></app-slot-a40>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
