<section class="relative w-full overflow-hidden pt-6 pb-8 lg:py-12 md:rounded-48 md:px-[6%]"
         [ngClass]="{'bg-deep-blue': contentModule.is_highlighted}"
         style="color: var(--page-accent);">
    <header class="w-full px-4 md:px-0 md:container flex flex-row items-center justify-between mb-4 lg:mb-12">

      <h1 class="flex-1 text-24 md:text-42 text-center md:text-left font-500 font-montserrat"
        [ngClass]="contentModule.is_highlighted ? 'text-neutral-white' : 'text-neutral-900'"
      *ngIf="!contentModule.name_hidden" >
        {{ contentModule.name }}
      </h1>

      <div class="flex-row items-center space-x-2 hidden md:flex">
        <button class="w-10 h-10 text-neutral-400 hover:cursor-pointer transition-colors ease-in-out nav-left-a60"
          [ngClass]="contentModule.is_highlighted ? 'hover:text-gold-400': 'hover:text-neutral-900'">
          <fa-icon class="text-24" [icon]="['fal', 'arrow-left']" [attr.aria-label]="'arrow left'"></fa-icon>
        </button>
        <button class="w-10 h-10 text-neutral-400 hover:cursor-pointer transition-colors ease-in-out nav-right-a60"
          [ngClass]="contentModule.is_highlighted ? 'hover:text-gold-400': 'hover:text-neutral-900'"
        >
          <fa-icon class="text-24" [icon]="['fal', 'arrow-right']" [attr.aria-label]="'arrow right'"></fa-icon>
        </button>
      </div>
    </header>

    <div class="w-full md:container">
      <div class="swiper swiper-module-a60">
        <div class="swiper-wrapper p-1 flex flex-row">
          <div class="swiper-slide pb-4 h-full" style="flex-grow: 1" *ngFor="let slotable of contentModule.slotables">
            <app-slot-a60 [slotable]="slotable"></app-slot-a60>
          </div>
        </div>
      </div>
    </div>

</section>
