import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Slot } from "../../models/slot.model";

@Component({
  selector: "app-slot-a80",
  templateUrl: "./slot-a80.component.html",
  styleUrls: ["./slot-a80.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SlotA80Component implements OnInit {
  @Input() slotable: Slot;
  @Input() isHighlighted: boolean = false;
  cleanDescription: string;

  ngOnInit(): void {
    if (this.slotable.description) {
      this.cleanDescription = this.handleDescription(this.slotable.description);
    }
  }
  handleDescription(val): string {
    return val.replace(/(&nbsp;|<([^>]+)>)/g, "");
  }
}
