import { Component, Input } from "@angular/core";
import { ContentModule } from "../../models/content-module.model";
import { ContentModuleBaseComponent } from "../content-module-base.component";

@Component({
  selector: "app-content-module-a080",
  templateUrl: "./content-module-a080.component.html",
})
export class ContentModuleA080Component implements ContentModuleBaseComponent {
  @Input() contentModule: ContentModule;
  constructor() {}
}
