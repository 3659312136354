<section class=" md:container relative md:w-full md:rounded-48 py-6 md:py-12"
      [ngClass]="{'bg-deep-blue': contentModule.is_highlighted }"
      style="color: var(--page-accent);">
    <div class="container grid md:gap-6 md:grid-cols-24">
      <div *ngFor="let slotable of contentModule.slotables"
           class="col-span-6 md:col-span-16 md:col-start-5">
        <app-slot-a91 [slotable]="slotable" [isHighlighted]="contentModule.is_highlighted"></app-slot-a91>
      </div>
    </div>
</section>
