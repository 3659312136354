<footer class="w-full">
  <div class="flex flex-col gap-4">
    <div
      class="bg-neutral-100 md:rounded-48 flex flex-col pb-8 overflow-hidden"
    >
      <div
        class="bg-gradient-to-r from-gradient-start to-gradient-end  overflow-hidden md:rounded-48 w-full flex flex-col md:flex-row md:justify-between gap-6 md:items-center p-6 md:p-12 relative"
      >
      <div class="absolute right-[-90px] top-[-20px] w-[268px] aspect-square">
        <img src="/assets/img/logos/rb-logo-gold-transparent.png" class="absolute inset-0 object-cover object-center w-full h-full" alt="RBCG">
      </div>
      <div class="container">
        <div class="flex flex-col max-w-[512px] gap-4 relative z-10">
          <h3
            i18n="@@contact_us"
            class="text-neutral-black font-montserrat font-500 text-24 leading-110"
          >
            Kontaktirajte nas
          </h3>
          <span
            i18n="@@contact_us_description"
            class="text-neutral-black font-400 text-16 leading-120"
          >
            Bilo da želite da nas pozovete ili pošaljete poruku, ovdje smo da
            vam pomognemo.
          </span>
          <button
            class="btn btn__primary--on-dark self-start md:self-center mt-2 relative z-20"
            i18n="@@more_details"
          >
            Više detalja
          </button>
        </div>
      </div>
      </div>

      <div
        class="container grid grid-cols-1 lg:grid-cols-4 gap-10 lg:gap-8 pt-6"
      >
        <div class="md:col-span-2 w-full">
          <div class="flex flex-col gap-3 md:flex-row">
            <div class="flex flex-col gap-[2px]">
              <span
                i18n="@@call_center"
                class="text-24 leading-120 font-montserrat font-700 text-neutral-900"
              >
                Call centar
              </span>
              <span
                class="text-neutral-800 text-18 leading-120"
                i18n="@@fre_info_number"
              >
                Besplatan info broj
              </span>
            </div>
            <a
              href="tel:080012012"
              class="font-500 font-montserrat text-42 leading-110 text-gold-800 hover:underline"
              >080 012 012</a
            >
          </div>
        </div>
                <div class="w-full flex flex-col gap-4 md:gap-6">
          <span
            i18n="@@development_bank_of_montenegro"
            class="font-700 text-20 text-neutral-900 leading-110"
          >
           Razvojna banka Crne Gore
          </span>
          <div class="flex flex-col gap-4">
            <span class="" i18n="@@address_line_1" class="leading-110 font-500 text-16 text-neutral-900">
              Bulevar Svetog Petra Cetinjskog 126/I, Podgorica
            </span>
            <span class="flex justify-start items-center gap-1">
              <span class="font-500 text-neutral-900 leading-110">email:</span>
              <a
                class="hover:underline text-gold-800 text-18 leading-110"
                href="mailto:info@rb.me"
                >info@rb.me</a
              >
            </span>
          </div>
        </div>
        <div class="w-full flex flex-col gap-4 md:gap-6">
          <span
            i18n="@@service_for_loands_and_warranties"
            class="font-700 text-20 text-neutral-900 leading-110"
          >
            Služba za kredite i garancije
          </span>
          <div class="flex flex-col gap-4">
            <span class="flex justify-start items-center gap-1">
              <span class="font-500 text-neutral-900 leading-110">tel:</span>
              <a
                class="hover:underline text-gold-800 text-18"
                href="tel:+38220416600"
                >+382 20 41 66 00</a
              >
            </span>
            <span class="flex justify-start items-center gap-1">
              <span class="font-500 text-neutral-900 leading-110">tel:</span>
              <a
                class="hover:underline text-gold-800 text-18"
                href="tel:+38220416601"
                >+382 20 41 66 01</a
              >
            </span>
          </div>
        </div>
        <div class="w-full flex flex-col gap-4 md:gap-6">
          <span
            i18n="@@office_in_bijelo_polje"
            class="font-700 text-20 text-neutral-900 leading-110"
          >
            Kancelarija u Bijelom Polju
          </span>
          <div class="flex flex-col gap-4">
            <span class="" i18n="@@address_line_1" class="leading-110 font-500 text-16 text-neutral-900">
              Ulica Slobode br. 1
            </span>
            <span class="flex justify-start items-center gap-1">
            </span>
            <span class="flex justify-start items-center gap-1">
              <span class="font-500 text-neutral-900 leading-110">tel:</span>
              <a
                class="hover:underline text-gold-800 text-18"
                href="tel:+382540431666"
                >tel: +382 540 43 16 66</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="container grid grid-cols-1 lg:grid-cols-4 gap-8">
      <span
        *ngFor="let menuItem of footerMenu"
        class="w-full flex flex-col gap-6"
      >
        <span
          class="font-montserrat text-neutral-900 text-24 font-700 leading-120"
        >
          {{ menuItem.title }}
        </span>
        <div class="flex flex-col gap-5">
          <a
            *ngFor="let childItem of menuItem.items"
            [routerLink]="
              childItem.type !== 'placeholder' ? childItem.link : null
            "
            [queryParams]="childItem.query_params"
            class="font-500 text-gold-800 text-18 leading-120 hover:cursor-pointer hover:underline"
            >{{ childItem.title }}</a
          >
        </div>
      </span>
    </div>
    <div
      class="w-full bg-deep-blue p-6 md:p-12 flex flex-col"
    >
      <div
        class="container flex flex-col gap-8 items-start pb-10 border-b border-azure-300"
      >
        <a routerLink="/" aria-label="home">
          <img src="/assets/img/logos/footer-logo.png" class="w-64 h-14" alt="logo"/>
        </a>
        <div class="flex gap-6 items-center">
          <a
            class="text-24 text-neutral-white hover:cursor-pointer"
            href="https://www.instagram.com/razvojna_banka_cg"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="instagram"
          >
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
          <a
            class="text-24 text-neutral-white hover:cursor-pointer"
            href="https://me.linkedin.com/company/RBCG"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="linkedin"
          >
            <fa-icon [icon]="faLinkedin"></fa-icon>
          </a>
          <a
            class="text-24 text-neutral-white hover:cursor-pointer"
            href="https://x.com/razvojna_banka"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="twitter"
          >
            <fa-icon [icon]="faXTwitter"></fa-icon>
          </a>
          <a
            class="text-24 text-neutral-white hover:cursor-pointer"
            href="https://www.facebook.com/razvojnabankacg"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="facebook"
          >
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>

        </div>
      </div>
      <div
        class="flex flex-col gap-8 md:flex-row justify-between items-start md:items-center pt-6"
      >
        <span
          class="text-neutral-400 text-12 leading-110 max-w-100"
        >
          © {{currentYear}}
          <span  i18n="@@irf_all_rights_reserved">
            Razvojna banka Crne Gore A.D. Sva prava zadržana.
          </span>
        </span>
      </div>
    </div>
  </div>
</footer>
