import { Component, Input } from "@angular/core";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

@Component({
  selector: "app-slot-a110",
  templateUrl: "./slot-a110.component.html",
})
export class SlotA110Component {
  @Input() slotable: any;

  faArrowRight = faArrowRight;
}
