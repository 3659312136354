import { ViewportScroller } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  APP_ID,
  Inject,
  LOCALE_ID,
  NgModule,
  PLATFORM_ID,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouteReuseStrategy, Scroll } from "@angular/router";
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from "@angular/service-worker";
import {
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsDownToPeople,
  faArrowUp,
  faBars,
  faBuildingColumns,
  faBullseyeArrow,
  faChartLineUp,
  faCircleInfo as falCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faCircleQuestion,
  faConveyorBeltBoxes,
  faEnvelope as falEnvelope,
  faFileLines,
  faHandHoldingSeedling,
  faHandshake,
  faHandsHoldingCircle as falHandsHoldingCircle,
  faHouseBuilding as falHouseBuilding,
  faLanguage,
  faLightbulbOn,
  faLocationDot,
  faMagnifyingGlass,
  faMinus,
  faNetworkWired,
  faPhone,
  faPlus,
  faRocketLaunch as falRocketLaunch,
  faShapes,
  faTractor as falTractor,
  faUmbrellaBeach as falUmbrellaBeach,
  faUser as falUser,
  faUserTag,
  faUserTieHairLong,
  faXmark,
  faXmarkLarge,
} from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faEnvelope,
  faHandsHoldingCircle,
  faHouseBuilding,
  faPersonCarryBox,
  faPrint,
  faQuoteRight,
  faRocketLaunch,
  faSquareCheck,
  faTractor,
  faUmbrellaBeach,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { filter } from "rxjs/operators";
import { SwiperModule } from "swiper/angular";
import { environment } from "../environments/environment";
import { DesignSystemModule } from "./@modules/design-system/design-system.module";
import { PagesModule } from "./@modules/page/pages.module";
import { FooterComponent } from "./@shared/components/footer/footer.component";
import { HeaderComponent } from "./@shared/components/header/header.component";
import { AppLayoutComponent } from "./@shared/layouts/app-layout/app-layout.component";
import { DesignSystemLayoutComponent } from "./@shared/layouts/design-system/design-system-layout.component";
import { NotFoundComponent } from "./@shared/layouts/not-found-layout/not-found.component";
import { SharedModule } from "./@shared/shared.module";
import {
  AppRoutingModule,
  HomepageResolver,
  PageResolver,
} from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BaseComponent } from "./Library/compontents/base.component";
import { CustomReuseStrategy } from "./Library/custom-reuse-strategy";
import { FormComponent } from "./Library/form/form.component";
import { LocaleInterceptor } from "./Library/interceptors/locale.interceptor";
import { UUIDInterceptor } from "./Library/interceptors/uuid.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AppLayoutComponent,
    NotFoundComponent,
    DesignSystemLayoutComponent,
    BaseComponent,
    FormComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "irf-website" }),
    TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    SharedModule,
    PagesModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register("ngsw-worker.js"),
    DesignSystemModule,
    SwiperModule,
  ],
  providers: [
    {
      provide: APP_ID,
      useValue: "base",
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UUIDInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({
        enabled: environment.production || environment.staging,
      }),
    },
    PageResolver,
    HomepageResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  urlBeforeRedirects: string;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) private platformId,
    router: Router,
    viewportScroller: ViewportScroller,
    library: FaIconLibrary,
  ) {
    router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: Scroll) => {
        if (e.position) {
          // backward navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition(e.position);
          }, 1000);
        } else if (e.anchor) {
          // anchor navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition(e.position);
          }, 1000);
        } else {
          // forward navigation

          const urlAfterRedirectsIndex =
            e.routerEvent.urlAfterRedirects.indexOf("?");
          let urlAfterRedirects = e.routerEvent.urlAfterRedirects;
          if (urlAfterRedirectsIndex > -1) {
            urlAfterRedirects = urlAfterRedirects.substr(
              0,
              urlAfterRedirectsIndex,
            );
          }
          if (this.urlBeforeRedirects) {
            const urlIndex = this.urlBeforeRedirects.indexOf("?");
            let url = this.urlBeforeRedirects;
            if (urlIndex > -1) {
              url = url.substr(0, urlIndex);
            }
            if (urlAfterRedirects !== url) {
              viewportScroller.scrollToPosition([0, 0]);
            }
          } else {
            viewportScroller.scrollToPosition([0, 0]);
          }
        }
        this.urlBeforeRedirects = e.routerEvent.urlAfterRedirects;
      });

    // let token = null;
    // if (isPlatformBrowser(this.platformId)) {
    //   token = localStorage.getItem('token');
    // }

    library.addIcons(
      faUser,
      faPersonCarryBox,
      faRocketLaunch,
      faTractor,
      faUmbrellaBeach,
      faHouseBuilding,
      faCircleInfo,
      faHandsHoldingCircle,
      faPrint,
      faEnvelope,
      faSquareCheck,
      faLocationDot,
      faPhone,
      faChevronUp,
      faChevronRight,
      faChevronDown,
      faChevronLeft,
      falUser,
      falEnvelope,
      falHandsHoldingCircle,
      falRocketLaunch,
      falTractor,
      falUmbrellaBeach,
      falHouseBuilding,
      falCircleInfo,
      faLanguage,
      faCircleMinus,
      faMinus,
      faCirclePlus,
      faMagnifyingGlass,
      faArrowUp,
      faArrowRight,
      faArrowDown,
      faArrowLeft,
      faPlus,
      faCircleQuestion,
      faXmark,
      faLightbulbOn,
      faArrowsDownToPeople,
      faHandshake,
      faChartLineUp,
      faUserTieHairLong,
      faUserTag,
      faCheck,
      faBars,
      faXmarkLarge,
      faPrint,
      faConveyorBeltBoxes,
      faShapes,
      faBullseyeArrow,
      faNetworkWired,
      faHandHoldingSeedling,
      faBuildingColumns,
      faFileLines,
      faQuoteRight,
    );
  }
}
