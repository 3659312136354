<section class="relative container w-full px-2 md:px-12">
  <header *ngIf="!contentModule.name_hidden" class="block w-full mt-6 mb-12">
    <h1 class="text-28 md:text-42 font-500 text-neutral-900 font-montserrat">
      {{ contentModule.name }}
    </h1>
  </header>
  <div class="container grid grid-cols-2 md:grid-cols-3 gap-4 grid-flow-row-dense">
    <div *ngFor="let slotable of contentModule.slotables"
         class="flex  bg-neutral-white rounded-16 item-center justify-center px-7 py-7 md:px-12 md:py-16 md:max-h-[204px] relative transition-all ease-in-out"
         [ngClass]="{ 'hover:border-neutral-400' : slotable.url }">
      <a *ngIf="slotable.url" [appFlLink]="slotable.url" title="{{ slotable.title }}" class="absolute inset-0 cursor-pointer"></a>
      <img *ngIf="slotable.image" style="aspect-ratio: 3/2;" class="object-contain"
           [src]="slotable.image.filename"
           [alt]="slotable.title">
    </div>
  </div>
</section>
