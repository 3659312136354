<section class="md:container px-4 md:px-0 md:w-full md:rounded-48 md:overflow-hidden "
  [ngClass]="{'bg-deep-blue md:rounded-48 pt-4 pb-8 md:pt-0 md:pb-0': contentModule.is_highlighted }">
  <div class="w-full relative">
    <div class="flex flex-col md:flex-row">
      <div class="relative w-full">
        <div class="relative w-full rounded-24 md:rounded-48 overflow-hidden md:w-10/24">
          <img class="w-full object-cover object-center rounded-24 md:rounded-48 aspect-video md:aspect-square" [src]="contentModule.image?.twitter " alt="{{ contentModule.image?.title }}">
        </div>
        <div class="static md:absolute md:inset-0 md:flex md:flex-row-reverse md:items-center">
          <div class="w-full md:w-13/24 pt-4 md:py-10 md:rounded-16">
            <div class="flex flex-col px-2 md:px-0">
              <h2 class="text-24 md:text-30 leading-110 text-neutral-900 font-500 font-montserrat"
              [ngClass]="{' text-neutral-white': contentModule.is_highlighted }">
                {{ contentModule.name }}
              </h2>
              <div class="w-full mt-4 md:mt-12 flex flex-col gap-3 md:pr-14 md:grid md:grid-cols-2 md:gap-8">
                <div *ngFor="let slotable of contentModule.slotables" class="w-full h-full">
                  <app-slot-a110 [slotable]="slotable"></app-slot-a110>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
