import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SeoItem } from './seo-item.model';

@Injectable({
  providedIn: 'root'
})
export class SeoTwitterService {

  private defaultText: string = '';
  private currentObject: any = null;
  private seoItem: SeoItem = null;
  private tw_title: string = '';

  constructor(
    private metaService: Meta
  ) {
  }

  setDefaultText(defaultText: string): void {
    this.defaultText = defaultText;
  }

  setCurrentObject(currentObject): void {
    this.currentObject = currentObject;
  }

  setSeoItem(seoItem: SeoItem): void {
    this.seoItem = seoItem;
  }

  reset(): void {
    this.metaService.removeTag('name="twitter:card"');
    this.metaService.removeTag('name="twitter:title"');
    this.metaService.removeTag('name="twitter:description"');
  }

  refresh(seoItem: SeoItem): void {

    const title = seoItem.seo_tw_title ? seoItem.seo_tw_title : seoItem.getTitle();
    const description = seoItem.seo_tw_description ? seoItem.seo_tw_description : seoItem.getDescription();

    this.metaService.updateTag({name: 'twitter:card', content: 'summary'});
    this.metaService.updateTag({name: 'twitter:title', content: title});
    this.metaService.updateTag({name: 'twitter:description', content: description});
    this.metaService.updateTag({name: 'twitter:site', content: '@razvojna_banka'});

    if (seoItem.seo_tw_image_url) {
      this.metaService.updateTag({name: 'twitter:image', content: seoItem.seo_tw_image_url});
    }
  }
}
